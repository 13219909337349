export default {
  getData (state) {
    return state.data
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  },
  getDataAllSales (state) {
    return state.data?.allSales || {}
  },
  getDataAllSalesToWeekPrice (state) {
    return state.data?.allSalesToWeekPrice || {}
  },
  getDataAllSalesToProducts (state) {
    return state.data?.allSalesToProducts || {}
  }
}