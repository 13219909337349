import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { ONE_HOUR } from '@/constants/time'

// const DEFAULT_TYPE = 'working-shift'
// const DEFAULT_DATE = [moment().subtract(1, 'months').format(), moment().format()]

export default {
  async loadData ({ commit, state }, { force = false } = {}) {
    if (!force
      && state.lastDataUpdated !== 0
      && state.data.length
      && Date.now() - state.lastDataUpdated < ONE_HOUR
    ) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.loadData() || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  }
}