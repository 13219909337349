export default {
  setData (state, payload) {
    if (!payload) {
      return
    }

    state.data = payload
  },
  setIsDataLoaded (state, payload) {
    state.isDataLoaded = payload
  },
  setLastDataUpdated (state) {
    state.lastDataUpdated = new Date().getTime()
  },
  clearState (state) {
    state.data = []
    state.isDataLoaded = false
    state.lastDateUpdated = 0
  }
}